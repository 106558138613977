.memberDetailWrapper footer.blockquote-footer {
	flex-direction: column;
	justify-content: center;
}
.memberDetailWrapper footer.blockquote-footer img.rounded-circle {
	width: 120px;
}
.memberDetailWrapper footer.blockquote-footer .testimony__info {
	align-items: center;
	flex-direction: column;
	justify-content: center;
	display: flex;
}
.memberDetailWrapper span.rating {
	align-items: center;
	justify-content: center;
	display: flex;
}
.memberDetailWrapper .memberMsg {
	/* text-align: center; */
	font-size: 1.5vw;
	font-size: 1.25rem;
	z-index: 1000 !important;
}
@media (max-width: 600px) {
	.memberDetailWrapper .memberMsg {
		font-size: 1.25rem;
	}
}
