.servicesContainer {
	.serviceCard {
		@media screen and (max-width: 768px) {
			flex: 0 0 100% !important;
		}
	}
	.services__card {
		display: flex;
		margin-bottom: 60px;
		position: relative;
		@media screen and (max-width: 768px) {
			flex-direction: column;
			margin-bottom: 40px;
		}
		.imageWrapper {
			position: relative;
			.serviceTitle {
				display: none;
				@media screen and (max-width: 768px) {
					position: absolute;
					display: block !important;
					bottom: 50px;
					left: 50%;
					transform: translateX(-50%);
					background-color: rgba(#0e689a, 0.75);
					color: white;
					margin: 0;
					text-align: center;
					border-radius: 10px;
					max-width: 100%;
					width: calc(100% - 40px);
					padding: 10px;
				}
			}
		}
	}
}
.servicesContainer .serviceTitle {
	margin-right: 40px;
	font-size: 30px;
	color: #0e689a;
	font-weight: bold;
	max-width: 300px;
	margin-top: 40px;
	text-align: right;
	flex: 1;
	@media screen and (max-width: 768px) {
		display: none;
	}
}
.servicesContainer .servicesImage {
	// margin-left: auto;
	width: 372px;
	height: 472px;
	border-radius: 4px;
	margin-right: 20px;
	border: 0.4px solid #c4c4c4;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
	@media screen and (max-width: 768px) {
		margin: 0 0 30px 0;
		width: 100%;
	}
}
.servicesContainer .servicesMessageWrapper {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 350px;
	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 30px;
		p {
			max-width: 100% !important;
		}
	}
}
.servicesContainer .servicesMessageWrapper p {
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	text-align: center;
	max-width: 265px;
	font-size: 18px;
	margin-bottom: 20px;
}
.servicesContainer .readMoreBtn {
	border-radius: 6px;
	color: white;
	padding: 8px 15px;
	font-size: 18px;
	outline: none;
	border-color: #0e689a;
	background-color: #0e689a;
}
/* detail */

.servicesDetailContainer .services__card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
}
.servicesDetailContainer .serviceTitle {
	margin-right: 20px;
	font-size: 30px;
	color: #0e689a;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 0px;
}
.servicesDetailContainer .servicesImage {
	width: 100%;
	max-height: 600px;
	border-radius: 4px;
	border: 0.4px solid #c4c4c4;
	filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}
.servicesDetailContainer .servicesMessageWrapper {
	display: flex;
	flex-direction: column;
}
.servicesDetailContainer .servicesMessageWrapper p {
	font-size: 18px;
	margin-bottom: 20px;
}

.servicesDetailContainer .info-name h4 {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 1.7rem;
	//   line-height: 35px;
	color: #5e5e5e;
	margin: 49px 0 0 0;
}

.servicesDetailContainer .info-name p {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1.7rem;
	//   line-height: 35px;
	color: #5e5e5e;
	margin: 49px 0 0 0;
}

// @media screen and (max-width: 480px) {
//   .servicesDetailContainer .info-name p {
//     text-align: center;
//   }
// }

.servicesDetailContainer .info-name ol {
	list-style-type: none;
	padding: 0;
	margin: 49px 0 0 0;
}

.servicesDetailContainer .info-name li {
	font-weight: inherit;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 1.7rem;
	//   line-height: 35px;
	color: #0e689a;
}

.servicesDetailContainer .info-name ul {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 1.7rem;
	//   line-height: 35px;
	color: #0e689a;
	margin: 49px 0 0 0;
	padding: 0 0 0 40px;
}
