.button-half-div {
	display: flex;
	justify-content: space-between;
	/* height: 10vh; */
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.button-half-div .btn1,
.btn2 {
	flex: 1;
	color: white;
	width: 80%;
	background-color: #166d9c;
	border: none;
	border-radius: 0 !important;
	margin: 10px;
	padding: 9.5px 10px;
}

.button-half-div .btn1 {
	background-color: #146d9c;
}

.profile-short-div {
	background-color: #e5e5e5;
	margin-top: 0px;
	margin-bottom: 0;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.quote-suggest-label {
	font-weight: bold;
	color: #146d9c;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #e5e5e5 !important;
	text-align: center;
	width: 25%;
	margin-right: 1%;
}

.btn3 {
	background-color: #146d9c;
	display: flex;
	align-items: center;
	justify-content: center;
}
.btn3_submit {
	background-color: #146d9c;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0;
	width: 97.7%;
	margin: 0px 10px;
	font-weight: bold;
}

.button-half-div.btn1:hover,
.btn2:hover,
.btn3:hover {
	/* background-color: #3c59e0 ;
    border-color: #3c59e0; */
}

.button-half-div.btn1:active,
.btn2:active,
.btn3:active {
	border: none;
}

.custom-suggest-quote .form-group {
	margin-bottom: 0;
}

.author-field {
	transition: all 0.3s ease-in-out;
}

.quote-section {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0;
}
.addhashtag {
	background: '#e5e5e5';
	border-radius: '0px';
	padding: '43px 20px';
}

.suggestquote-user-profile-picture {
	margin: 5px;
	height: 40px;
	width: 40px;
	object-fit: cover;
}
.suggestMsg {
	margin-left: 10px;
	margin-bottom: 0;
	margin-top: 15px;
	font-size: 0.8rem;
}
.suggestquote-user-name {
	color: #146d9c;
}

.quoteSuggestLabel {
	display: flex;
	align-items: center;
	margin-bottom: 2px;
	font-weight: bold;
	color: #146d9c;
}
.quotesGuidLine h4 {
	font-weight: bold;
	color: #146d9c;
}
.quotesGuidLine ul {
	margin-left: 15px;
	font-size: 0.7rem;
}
.quotesGuidLine ul li {
	margin-bottom: 5px;
}
.quoteSuggestLabel .suggestMsg {
	margin-top: 0;
	font-weight: normal;
	color: #4f5464;
}

.suggest-quote-user-profile-container,
.suggest-quote-submit-button-div {
	display: flex;
	justify-content: start;
	align-items: center;
	background-color: #e5e5e5;
	width: auto;
	margin: 10px;
	padding: 2px;
}

.suggest-quote-quote-textarea {
	height: 20vh !important;
}

.suggest-quote-submit-button-div .btn3_submit {
	width: 100%;
	margin: 0;
	border: none;
	border-radius: 0 !important;
}

@media only screen and (max-width: 600px) {
	.btn3_submit {
		width: 94%;
		margin: 0px 10px;
	}
	.quote-suggest-label {
		font-size: 10px;
	}
	.addhashtag {
		padding: '34px 20px';
	}

	.quote-section {
		padding: 10px;
		margin-left: 0;
	}

	.suggestquote-user-name {
		font-size: 14px;
	}
	.suggestquote-user-profile-picture {
		height: 40px;
		width: 40px;
		object-fit: cover;
	}

	.profile-short-div-center {
		margin: 0 !important;
		display: flex;
		align-items: center;
		justify-content: start;
	}
}
