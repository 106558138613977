.new-quote-cards-container {
	display: flex;
	background-color: #f4f4f4;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px 10px;
	padding-top: 0px;
}

.new-quote-cards-left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #146d9c !important;
}
@media (max-width: 600px) {
	.res-btn-size-12 {
		font-size: 18px !important;
	}
}
.quotes-nav-link {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	border-radius: 0 !important;
	/* height: 8vh; */
	text-align: center;
}
.new-quote-cards-left h5 {
	color: #146d9c !important;
}

.save-div {
	display: flex;
	align-items: flex-start;
}

.new-quote-cards-right {
	margin-top: auto;
	margin-bottom: auto;
	background-color: #166d9c;
	padding-top: auto;
	width: 150px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 22px;
}

.save-div p {
	font-size: 12px;
}

.cmapign-download {
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	color: white;
}

.new-quote-cards-right:hover {
	background-color: #166d9c;
}
.card-text {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	color: #166d9c;
}
.block-claim-text {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	text-align: center;

	color: #ffffff;
}

.additional-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* flex-wrap: wrap; */
	width: 100%;
}
.individual-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 15px;
	margin-bottom: 1px;
}
.info-heading {
	background: #166d9c;
	padding: 4px 10px;
	margin-right: 5px;
}
.tagMobile,
.viewImageMobile {
	display: none;
}
@media only screen and (max-width: 600px) {
	.info-heading.tag-btn {
		display: none;
	}
	.tagMobile {
		color: #898989 !important;
		display: block;
		margin-right: 3px;
		font-weight: bold;
		font-size: 12;
	}
	.info-heading .tag {
		color: #898989 !important;
	}
	.viewImageDisktop {
		display: none;
	}
	.viewImageMobile {
		display: flex;
		margin: 8px;
	}
	.viewImageMobile .radius-5 {
		border-radius: 3px;
		padding: 4px;
		height: 100%;
		margin: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.info-heading span {
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;

	color: #ffffff;
}
.info-value {
}
.info-value span {
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	text-decoration-line: underline;
	color: #898989;
	margin-right: 5px;
}

.myquotes-card-col {
	padding-left: 0;
}

.myquotes-card-col .card-image {
	height: auto;
}

/* trending quotes card css */

.trending-quotes-card-button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.trending-quotes-claim-button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #166d9c;
	border: none;
	border-radius: 3px;
	padding: 4px;
	width: 100%;
}

.block-claim-text {
	margin: 0;
	padding: 5px;
	font-size: 16px;
}

.user-pic-icon {
	width: 16px;
	margin-right: 12px;
}

.quote-container-div {
	height: 16vh;
	background: white;
	border: 1px solid #c4c4c4;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
}
@media only screen and (max-width: 600px) {
	.quote-container-div {
		padding: 10px 30px;
		background-color: transparent;
		border: none;
		height: auto;
	}
}

.profile-topbar-winner-icon {
	color: #166d9c;
}

@media only screen and (max-width: 600px) {
	.new-quote-cards-container {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
	}

	.myquotes-card-col {
		padding: 0;
		margin-top: 5vh;
	}

	.new-quote-cards-left {
		padding: 8px;
		padding-top: 0;
	}

	.trending-quotes-claim-button {
		margin: 8px;
		width: 100%;
	}

	.block-claim-text {
		font-size: 12px;
	}

	.quote-card-quote-text {
		font-size: 12px;
	}

	.trending-quotes-dp {
		margin-left: 0;
	}
	.profile-topbar-bar {
		font-size: 12px !important;
	}
}
