.shift-right {
	margin-left: 22%;
}
.nav-search {
	border-radius: 0px;
}
@media (max-width: 1440px) {
	.shift-right {
		margin-left: 1%;
	}
	.nav-search {
		margin-left: 0%;
	}
}
@media (min-width: 1440px) {
	.shift-right {
		margin-left: 20%;
	}
	.nav-search {
		margin-left: 2%;
	}
}
