.sidebar-campaign {
	/* background-color: #E5E5E5; */
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 !important;
	height: 80vh;
}
.sidebar-profile {
	/* background-color: #E5E5E5; */
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 !important;
	height: 60vh;
}
.suggest-quote-button {
	background-color: #146d9c;
	border: none;
	width: 100%;
	border: none;
	padding: 10px;
	color: white;
	font-weight: bold;
}

.sidebar-heading-button {
	display: flex;
	text-align: center;
	justify-content: space-between;
	margin-top: 5px;
	margin-bottom: 5px;
	font-weight: normal;
	font-size: 14px;
}

.campaign-list-sidebar {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
}

.sidebar-list-item {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	width: 100%;
	align-items: center;
	margin: 5px;
	margin-left: 0;
	margin-right: 0;
	cursor: pointer;
}
.sidebar-list-item .grey-box {
	border: 0.5px solid #e5e5e5;
	padding: 10px;
}

.sidebar-list-item .claim-hover:hover {
	color: #146d9c !important;
}
@media screen and (max-width: 768px) {
	.sidebarProfile {
		display: none !important;
	}
}
