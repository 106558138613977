.tagList .btnList {
	flex-wrap: wrap;
	font-weight: bold;
	margin-bottom: 20px;
	color: rgb(20, 109, 156);
}
.tagList .btnList span {
	font-weight: bold;
}
.tagList .btnList span a {
	font-weight: bold;
}
.tagList .btnList span:not(:last-child) {
	margin-right: 5px;
	padding-right: 5px;
	border-right: 1px solid rgb(20, 109, 156);
}

.tagList ul {
	font-size: 12px;
	list-style-type: none;
}
.tagList ul li {
	margin-bottom: 2px;
	font-weight: bold;
}
.tagList .myQuotes {
	cursor: pointer;
}
.tagList .tagWrapper {
	cursor: pointer;
}
