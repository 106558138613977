.profile-container {
	padding-top: 0 !important;
	@media screen and (max-width: 640px) {
		padding-top: 10px !important;
		margin-top: 0px !important;
	}
}
.profileDisplay {
	.detailWrapper {
		position: relative;
		justify-content: center;
		display: flex;
		margin-bottom: 10px;
		&.hideEdit {
			align-items: center;
		}
		.userImageDiv {
			justify-content: flex-start;
			.dp {
				border: 3px solid #fb5757 !important;
				padding: 3px;
				&::before {
					content: none;
				}
				img {
					border-radius: 50%;
				}
			}
			@media screen and (max-width: 640px) {
				display: none;
			}
		}
		.userDetail {
			display: flex;
			flex-direction: column;
			padding-left: 60px;
			font-size: 14px;
			color: #898989;
			.editDP {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.userImageDiv {
					display: none;
					@media screen and (max-width: 640px) {
						display: block;
					}
				}
				@media screen and (max-width: 640px) {
					flex-direction: column;
					.userImageDiv {
						margin-bottom: 10px;
					}
					.editButton {
						margin-right: 0;
						margin-bottom: 8px;
						padding: 6px 16px;
					}
				}
			}
			.editButton {
				padding: 15px 20px;
				margin-right: auto;
				border: none;
				font-size: 16px;
				font-weight: bold;
				border-radius: 5px;
				background-color: #146d9c;
				color: white;
			}
			.socialDetail {
				margin-top: 20px;
				display: flex;
				color: #898989;
				font-size: 14px;
				.socialItem {
					&:not(:last-child) {
						margin-right: 60px;
					}
				}
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
			.userName {
				font-size: 18px;
				font-weight: bold;
				@media screen and (max-width: 640px) {
					font-size: 20px;
					text-align: center;
				}
			}
			.usertype {
				@media screen and (max-width: 640px) {
					text-align: center;
				}
			}
			.linkdinURL {
				color: #166d9c;
				font-weight: bold;
				margin-bottom: 15px;
				@media screen and (max-width: 640px) {
					text-align: center;
				}
			}
			@media screen and (max-width: 640px) {
				padding-left: 0;
			}
		}
		.line {
			position: absolute;
			width: 100%;
			// left: 10%;
			// right: 10%;
			bottom: 0;
			margin: 30px auto 0;
			border-bottom: 0.5px solid #b5b5b5;
			@media screen and (max-width: 640px) {
				width: 100%;
				left: 0;
				right: 0;
				display: none;
			}
		}
		@media screen and (max-width: 640px) {
			margin-bottom: 5px;
		}
	}

	.otherbtn {
		display: flex;
		justify-content: center;
		button {
			width: 170px;
			padding: 10px 20px;
			font-size: 16px;
			font-weight: bold;
			border: none;
			border-radius: 5px;
			background-color: #146d9c;
			color: white;
			@media screen and (max-width: 640px) {
				font-size: 12px;
			}
		}
		.saveQBtn {
			svg {
				height: 100%;
				display: none;
			}
			span {
				font-size: 16px;
				font-weight: bold;
			}
			@media screen and (max-width: 640px) {
				background-color: transparent;
				width: auto;
				padding: 0;
				svg {
					display: block;
				}
				span {
					display: none;
				}
			}
		}
	}
	.userPoste {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 15px 0;
		.myPosts {
			width: calc(33% - 10px);
			padding: 10px;
			border: 0.5px solid #e3e5ee;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-bottom: 15px;
			@media screen and (max-width: 640px) {
				width: 100%;
			}
			img {
				width: 100%;
				// height: 100%;
			}
			.reclaimBtn {
				margin-top: 10px;
				padding: 10px 30px;
			}
		}
		.commingSoon {
			margin-top: 30px;
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			margin: 0 auto;
		}
	}

	&.editProfileDisplay {
		display: flex;
		@media screen and (max-width: 640px) {
			flex-direction: column;
		}
		.tabView {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			@media screen and (max-width: 640px) {
				flex-direction: row;
			}
			span {
				margin-bottom: 20px;
				font-weight: bold;
				cursor: pointer;
				color: #166d9c !important;
				@media screen and (max-width: 640px) {
					margin-right: 20px;
					font-size: 16px;
				}
			}
		}
		.contentView {
			flex: 1;
			padding-left: 30px;
			margin-left: 30px;
			border-left: 0.5px solid #898989;
			@media screen and (max-width: 640px) {
				border: none;
				padding-left: 0;
				margin-left: 0;
			}
			.detailWrapper {
				justify-content: flex-start !important;
				margin-bottom: 20px;
				.userImageDiv {
					min-width: 205px;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;

					@media screen and (max-width: 640px) {
						min-width: auto;
					}
					.dp {
						width: 65px;
						height: 65px;
						@media screen and (max-width: 640px) {
							width: 75px;
							height: 75px;
						}
					}
				}
				.userDetail {
					padding-left: 40px !important;
					justify-content: center;
					@media screen and (max-width: 640px) {
						padding-left: 20px !important;
					}
				}
			}
			.userName {
				color: #898989;
				font-size: 24px;
				font-weight: bold !important;
			}
			.editButton {
				color: #166d9c !important;
				font-size: 18px;
				padding: 0 !important;
				background-color: transparent !important;
			}
			.form-input {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 15px;
				@media screen and (max-width: 640px) {
					flex-direction: column;
				}
				.inputDiv {
					position: relative;
					flex: 1;
					.passInputIcon {
						position: absolute;
						right: 15px;
						cursor: pointer;
						width: 20px;
						top: 50%;
						transform: translateY(-50%);
					}
					@media screen and (max-width: 640px) {
						min-width: 100%;
					}
				}

				label {
					min-width: 205px;
					margin-right: 40px;
					text-align: right;
					color: #898989;
					font-size: 16px;
					font-weight: bold;
					@media screen and (max-width: 640px) {
						text-align: left;
						margin-bottom: 10px;
						margin-right: 0;
						min-width: 100%;
					}
				}
				input {
					width: 100%;
					font-weight: bold;
					flex: 1;
					height: 50px;
					padding: 10px 15px;
					border: 0.5px solid #b5b5b5;
					border-radius: 5px;
					font-size: 14px;
					color: #166d9c !important;
					@media screen and (max-width: 640px) {
						min-width: 100%;
					}
				}
				.checkBoxInput {
					display: flex;
					align-items: center;
					flex: 1;
					padding: 10px 15px;
					border: 0.5px solid #b5b5b5;
					border-radius: 5px;
					color: #898989;
					font-weight: bold;
					font-size: 14px;
					@media screen and (max-width: 640px) {
						min-width: 100%;
						flex-direction: row-reverse;
						justify-content: space-between;
						padding: 10px 15px;
					}

					input {
						flex: none;
						margin-right: 15px;
						width: 24px;
						height: 24px;
						@media screen and (max-width: 640px) {
							width: 24px;
							height: 24px;
							min-width: auto;
							margin-left: 15px;
							margin-right: 0;
						}
					}
				}
				.submitBtn {
					font-weight: bold;
					background-color: #166d9c;
					border: none;
					border-radius: 5px;
					width: 100%;
					padding: 10px 15px;
					color: white;
					margin-top: 20px;
					@media screen and (max-width: 640px) {
						font-size: 16px;
					}
				}
			}
		}
	}
}
