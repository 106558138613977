.loading-modal-ctn {
  width: 30%;
  min-width: fit-content;
}

.loading-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-obj-ctn {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
