.my-facebook-button-class {
	width: 100%;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	color: #fff;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	background-color: #007bff;
	padding: 0.75rem 2.5rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.googleBtn button {
	width: 100%;
	justify-content: center;
	box-shadow: 0 0px 30px 0 rgb(0 0 0 / 20%), 0 6px 15px 0 rgb(0 0 0 / 19%) !important;
}
.googleBtn button span {
	color: black;
}
.googleBtn button svg {
	width: 100%;
}
