.paginationContainer {
	margin-bottom: 20px;
	.btnBox {
		border: 1px solid #146d9c;
		border-radius: 5px;
		.btn {
			border-radius: 0;
		}
		.numberWrapper {
			align-items: center;
			button {
				outline: none;
				border: none;
				background-color: transparent;
				padding: 3px 5px;
				height: 100%;
				min-width: 30px;
			}
			button:not(:last-child) {
				border-right: 1px solid #146d9c;
			}
		}
	}
}
