.suggestQuotes {
	.MuiFormControl-root {
		border-radius: 5px;
		background-color: #fb5757 !important;
	}
	.MuiInput-underline {
		&:before {
			content: none;
		}
	}

	.MuiAutocomplete-hasPopupIcon {
		&.MuiAutocomplete-hasClearIcon {
			.MuiAutocomplete-inputRoot {
				padding-right: 57px !important;
				padding-left: 15px !important;
				input {
					border: none;
					border: 5px;
					font-size: 12px;
					font-weight: bold;
					color: white !important;
					background-color: transparent !important;
					height: auto !important;
					padding: 12px 0;
					&::placeholder {
						color: white !important;
					}
				}
			}
		}
	}
	.MuiAutocomplete-endAdornment {
		right: 5px !important;
		.MuiAutocomplete-popupIndicator {
			color: white;
		}
	}
}
