.trending-card {
  margin-bottom: 8vh;
}
.show-deshtop {
  display: inline;
}
.show-mobile {
  display: none;
}
.iframe {
  height: 257px;
  width: 320px;
}
.svg {
  width: 100% !important;
  padding-left: 5px;
}
.bg-winner {
  background-color: #146d9c !important;
}
.long-text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  margin-left: 10px;
}
@media (max-width: 600px) {
  .res-left {
    left: 30px !important;
  }
  .long-text-wrap {
    width: 100%;
  }
  .show-desktop {
    display: none;
  }
  .frame-padding {
    padding: 0 !important;
    margin: 10px;
  }
  .iframe {
    height: 200px;
    width: 358px;

    margin-top: -18px;
  }
  .show-mobile {
    display: inline;
  }
}
.trendingcard-header {
  background-color: #e5e5e5;
  display: flex;
  justify-content: space-between;
  /* padding-left: 5px;
    padding-right: 5px; */
  align-items: center;

  margin: 0;
  height: 8vh;
}
.winner-nav-link {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border-radius: 0 !important;
  /* height: 8vh; */
  text-align: center;
}
.trendingcard-header h5 {
  margin: 0;
  margin-left: 10px;
}

.trendingcard-leftside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.serial-number-div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #146d9c;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
}

.trendingcard-claimbutton {
  font-size: 12px;
  padding: 6px;
}

.trendingcard-footer {
  justify-content: space-around;
  text-align: center;
}
.trending-card-trophy {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-content-title {
  color: #4e7ca2;
  margin: 0;
}
.w-border {
  border: 2px solid white;
}

@media (max-width: 600px) {
  .footer-content-title {
    font-size: 8px;
  }
  .footer-winner {
    font-size: 12px;
  }
  .trendingcard-header {
    height: 5vh;
  }
}

.view-winner-title a {
  font-weight: bold;
}

.learn-more-icon {
  font-size: 5px;
  margin-left: 50px;
  margin-right: 20px;
  width: 26px;
}
.learn-more {
  border-bottom: 2px solid white;
  text-align: left;
}
