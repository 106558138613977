.searchInput,
#suggestQuotesID {
	.MuiFormControl-root {
		border-radius: 5px;
		background-color: white !important;
		border: 1px solid #e3e5ee;
		height: 50px;
	}
	.MuiInput-underline {
		&:before {
			content: none;
		}
	}

	.MuiAutocomplete-hasPopupIcon {
		&.MuiAutocomplete-hasClearIcon {
			.MuiAutocomplete-inputRoot {
				height: 50px;
				padding-right: 57px !important;
				padding-left: 15px !important;
				display: flex;
				align-items: center;
				input {
					width: 100%;
					border: none;
					font-size: 1rem;
					color: black !important;
					background-color: transparent !important;
					height: auto !important;
					padding: 12px 0;
					&::placeholder {
						color: black !important;
					}
					&:focus {
						box-shadow: none !important;
					}
				}
			}
		}
	}
	.MuiAutocomplete-endAdornment {
		right: 5px !important;
		.MuiAutocomplete-popupIndicator {
			color: black;
		}
	}
}
.mobileLogoutBtn {
}
.profileDiplayView {
	.userImg {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.mainInfo {
		display: flex;
		flex-direction: column;
		.row {
			margin: 10px 0;
			display: flex;
			justify-content: space-between;
			& > div {
				width: calc(50% - 10px);
			}
		}
	}
	.nameTitle {
		font-weight: bold;
		font-size: 24;
	}
	table {
		tbody {
			tr {
				td {
					.artworkImage {
						display: flex;
						justify-content: center;
						img {
							max-width: 70px;
						}
					}
				}
			}
		}
	}
}
.allMemberWrapper {
	.allMemberNameContainer {
		display: flex;
		align-items: center;
		.allMemberProfiledp {
			width: 30px;
			margin-right: 10px;
			height: 30px;
			border-radius: 50%;
		}
	}
	table {
		tbody {
			td {
				.create-team-btn {
					padding: 8px 15px !important;
				}
			}
		}
	}
}
