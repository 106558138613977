.winnerCampainWrapper {
	.MuiFormControl-root {
		border-radius: 5px;
		background-color: #166d9c !important;
	}
	.MuiInput-underline {
		&:before {
			content: none;
		}
	}

	.MuiAutocomplete-hasPopupIcon {
		&.MuiAutocomplete-hasClearIcon {
			.MuiAutocomplete-inputRoot {
				padding-right: 57px !important;
				padding-left: 15px !important;
				input {
					border: none;
					border: 5px;
					font-size: 12px;
					font-weight: bold;
					color: white !important;
					background-color: transparent !important;
					height: auto !important;
					padding: 12px 0;
					&::placeholder {
						color: white !important;
					}
				}
			}
		}
	}
	.MuiAutocomplete-endAdornment {
		right: 5px !important;
		.MuiAutocomplete-popupIndicator {
			color: white;
		}
	}
	.bestContentCreaterBtn {
		@media screen and (max-width: 768px) {
			max-width: 120px;
			text-transform: capitalize;
		}
	}
	.profileName {
		@media screen and (max-width: 768px) {
			font-size: 14px;
		}
	}
}

@media screen and (max-width: 768px) {
	.myCompainContainer {
		.containerWrapper {
			margin-top: 0;
		}
		.lineBreaker {
			display: none;
		}
	}
}
