.quotesScreen .winner-button {
	width: 32%;
}
@media only screen and (max-width: 900px) {
	.quotesScreen .winner-button:nth-child(1) {
		width: 32%;
		margin-top: 10px;
	}
}
.authorQuotesScreen .quotesTitle,
.quotesScreen .quotesTitle,
.myQuotesScreen .quotesTitle {
	font-size: 1.8rem;
	margin-bottom: 10px;
	color: #166d9c;
	font-weight: bold;
}
